<template>
  <div class="priceCpiponBox">
    <iHeader
      backType="blank"
      :headStyle="{
        background: 'var(--themeColor)',
        color: '#fff'
      }"
      :backEvent="true"
      @back-event="priceCouponGoBack()"
      :back="true"
    >
      <template v-slot:rightBtn v-if="!isPlaceOrder && isNoOrder">
        <div @click="isloding && saveItem()">保存</div>
      </template>
    </iHeader>
    <div v-if="isCompletedOrder && onlineDetail.sourceCode == 'DiDi'" class="didiMask" @click="isGoh5(SmartStorage.get('orderDetail'), 'priceCoupon', true)">查看行驶轨迹</div>
    <div id="containerP" :class="['maps', [0].includes(onlineDetail.status) && 'bigMap', isNoOrder && 'smaiMap']"></div>
    <!-- <img
      v-if="lodingPrice"
      class="grtPriceLoading"
      src="../../assets/images/loading2.gif"
    /> -->
    <svg v-if="lodingPrice" class="icon carLodingIcon transition_dom" aria-hidden="true">
      <use xlink:href="#iconhuiyinqing" />
    </svg>
    <div v-if="!Loading">
      <!-- <priceCoupon :saveItem="saveItem"
        :isloding="isloding" @chosePrices="chosePrices" :levelList="levelList.prices" :didiPrice="didiPrice" :itemData="params.ItemData"></priceCoupon> -->
      <div
        :class="[
          'priceCouponBox',
          'transition_dom',
          isNoOrder && 'hasBack',
          isPlaceOrder && 'placeOrderPriceCouponBox',
          isReceivedOrder && 'receivedPriceCouponBox',
          onlineDetail.extOrderStatus == 10 && 'noDriverOrderPriceCouponBox',
          // isCompletedOrder &&!(onlineDetail.itsItemData&&onlineDetail.itsItemData.OwnerConfirmDescr||approveBtn.length!=0) && 'CompletedPriceCouponBox',
          isCompletedOrder &&showApproveBox&&!(onlineDetail.itsItemData.OwnerConfirmDescr||approveBtn.length!=0) && 'CompletedPriceCouponBox',
          [-1, 0].includes(onlineDetail.status) && !isPlaceOrder && 'cancelPriceCouponBox',
          [0].includes(onlineDetail.status) && !isPlaceOrder && 'unOrderPriceCouponBox',
          hasOtherBtn && [10, 100].includes(onlineDetail.extOrderStatus) && 'hasOtherBtnPriceCouponBox',
          hasOtherBtn && [30, 40, 50, 60].includes(onlineDetail.extOrderStatus) && 'hasOtherBtnPriceCouponBox2',
          ['novartis'].includes($cookies.get('tenant'))&&showApproveBox&&(onlineDetail.itsItemData.OwnerConfirmDescr||approveBtn.length!=0) && 'approvePriceCouponBox'
        ]"
      >
        <div class="pricescrollBox">
          <div class="topbox">
            <span></span>
          </div>
          <div class="fiexBox" v-if="isNoOrder && !isPlaceOrder">
            <div class="levelbox">
              <span @click="goLevel('滴滴', true)" :class="isDiDi && 'choiseLevel'">
                滴滴
                <p>{{ Math.ceil(didiPriceList.minPrice) }}~{{ Math.ceil(didiPriceList.maxPrice) }}元</p>
              </span>
              <template v-for="(type, tindex) in levelList.prices">
                <span :class="type.typeSelected && 'choiseLevel'" @click="goLevel(type)" v-if="type.prices.length > 0" :key="tindex">
                  {{ type.carLevel }}
                  <p>{{ Math.ceil(type.minPrice) }}~{{ Math.ceil(type.maxPrice) }}元</p>
                </span>
              </template>
            </div>
          </div>

          <div class="priceCouponContent" @scroll="scrollEvent" ref="pricescrollBox">
            <template v-if="isNoOrder && !Loading">
              <noOrder
                :levelList="levelList.prices"
                :didiPriceList="didiPriceList"
                @chosePrices="chosePrices"
                :isPlaceOrder="isPlaceOrder"
                :itemData="params.ItemData"
                :saveItem="saveItem"
                :params="params"
                :cancelOrders="cancelOrders"
                ref="noOrderRef"
              ></noOrder>
            </template>
            <template v-if="isReceivedOrder">
              <receivedOrder :onlineDetail="onlineDetail"></receivedOrder>
            </template>
            <template v-if="isCompletedOrder">
              <completedOrder :onlineDetail="onlineDetail" :hasOtherBtn="hasOtherBtn"></completedOrder>
            </template>
          </div>
        </div>
        <bottomContent
          :onlineDetail="onlineDetail"
          :itemData="params.ItemData"
          :isReceivedOrder="isReceivedOrder"
          :isCompletedOrder="isCompletedOrder"
          :isPlaceOrder="isPlaceOrder"
          :saveItem="saveItem"
          :isloding="isloding"
        ></bottomContent>
        <!-- 山德士拒绝审批按钮审批拒绝原因 -->
        <div class="approve_btn" v-if="['novartis'].includes($cookies.get('tenant'))&&showApproveBox&&(onlineDetail.itsItemData.OwnerConfirmDescr||approveBtn.length!=0)">
          <div class="car_remarks" v-if="onlineDetail.itsItemData.OwnerConfirmDescr">
            <div class="car_remarks_con">拒绝原因: {{ onlineDetail.itsItemData.OwnerConfirmDescr }}</div>
          </div>
          <div class="approve_btn_list" v-if="SmartStorage.get('orderDetail').TaskId">
            <div v-for="item in approveBtn" :key="item.actionId" @click="showApprovePop(item)">
              {{ item.actName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carLevelsOne carLoding" v-else>
      <Skeleton :skList="skList" />
      <img src="../../assets/images/carLoding.gif" />
    </div>
    <MessageBox ref="tipsMsg" type="tips" :btnType="btnType" singleTxt="确定" :messageTxt="messageTxt" @on-single="MessageBtn()" @on-ok="MessageBtn()"></MessageBox>
    <cancelResonPop ref="cancelResonPop" :canselect="canselect" :cancelReson="cancelReson"></cancelResonPop>
    <approveRefused ref="approveRefused"></approveRefused>
    <returnBox
      :retype="'carRemark'"
      @closeDialog="isCarRemarkDialog = false"
      @quotePiece="SaveUseCarRemark"
      :isloding="isloding"
      :showBtn="showBtn"
      :carRemark="onlineDetail.itsItemData.useCarRemark"
      v-if="isCarRemarkDialog"
    ></returnBox>
    <projectGrade ref="projectGrade" :refrashData="refrashData"></projectGrade>
    <browserLoader :iframeUrl="iframeUrl" v-if="showDiDiH5" :h5GoClose="h5GoClose" :browserClose="browserClose"></browserLoader>
    <Map ref="mapPop" @close="refrashData" />
  </div>
</template>
<script>
import browserLoader from '@/views/common/platform/browserLoader';
import projectGrade from '@/views/common/projectGrade/projectGrade';
import returnBox from '@/views/common/returnBox';
import axios from 'axios';
import cancelResonPop from '@/views/common/platform/cancelResonPop';
import approveRefused from '@/views/common/platform/approveRefused'; //审批拒绝
import noOrder from '@/views/common/platform/priceCoupon/noOrder';
import completedOrder from '@/views/common/platform/priceCoupon/completedOrder';
import receivedOrder from '@/views/common/platform/priceCoupon/receivedOrder';
import bottomContent from '@/views/common/platform/priceCoupon/bottomContent';
import submitOrder from '@/views/controllers/submitOrder.js';
import mapSet from '@/views/controllers/mapSet.js';
import Map from '@/views/common/map/map';
import filterEmoji from '@/views/controllers/filterEmoji.js';
export default {
  mixins: [mapSet, submitOrder, filterEmoji],
  data() {
    return {
      btnType: 'double',
      iframeUrl: '',
      showDiDiH5: false,
      showBtn: true,
      isCarRemarkDialog: false,
      polyline: [],
      arratGps: [],
      cancelReson: [],
      canselect: true,
      reason: '',
      otherReason: '',

      onlineDetail: {},
      isDiDi: true,
      isNoOrder: true,
      isPlaceOrder: false,
      isReceivedOrder: false,
      isCompletedOrder: false,
      skList: [
        {
          width: '65%',
          row: 2
        },
        {
          width: '20%',
          position: 'right'
        }
      ],
      estimateDistance: 0,
      tokenFlage: true,
      messageType: '',
      messageTxt: '',
      isChoseType: true,
      choiseItemId: '',
      IsCheckRepeatOrder: 1,
      saveType: 1,
      isloding: true,
      lodingPrice: false,
      isDiDiqiye: false,
      params: {
        IsExtra: true,
        ItemData: {}
      },
      markerList: [],
      marker: {},
      Loading: true,
      levelList: {
        userPersonalizations: {},
        prices: []
      },
      arrryLeves: [],
      priceArr: {},
      didiPrice: [],
      didiPriceList: {
        minPrice: 0,
        maxPrice: 0
      },
      obj: { 经济型: [], 舒适型: [], 商务型: [] },
      arr: [],
      iindex: 0,
      che: [],
      maplineArra: [],
      hasOtherBtn: false,
      approveBtn: [] ,//审批拒绝按钮
      showApproveBox:false,
    };
  },
  async mounted() {
    let data = this.SmartStorage.get('priceCouponData');
    if (data) {
      this.params.ItemData = data;
      this.getPriceCouponStream(data);
      this.initMap();
      this.$nextTick(() => {
        if (this.SmartStorage.get('orderDetail') && this.SmartStorage.get('orderDetail').Status == 2) {
          this.isPlaceOrder = true;
          this.installSingler();
        }
        this.GetUserToken();
      });
    } else {
      await this.getOrderGetDetail();
      this.showApproveBox=true;//区分是新增订单还是view订单
      this.installSingler();
      if (['novartis'].includes(this.$cookies.get('tenant')) && this.SmartStorage.get('orderDetail').TaskId) {
      this.getQueryTaskActions();
    }
    }

    this.iindex = 0;
    this.arr = [
      {
        lng: '121.44562',
        lat: '31.24663'
      },
      {
        lng: '121.44553',
        lat: '31.24668'
      },
      {
        lng: '121.44478',
        lat: '31.24699'
      },
      {
        lng: '121.44443',
        lat: '31.24689'
      },
      {
        lng: '121.44483',
        lat: '31.24466'
      },
      {
        lng: '121.44502',
        lat: '31.24431'
      },
      {
        lng: '121.45044',
        lat: '31.24524'
      },
      {
        lng: '121.45156',
        lat: '31.24495'
      }
    ];
    this.che = [
      {
        lng: '121.44562',
        lat: '31.24663'
      },
      {
        lng: '121.44553',
        lat: '31.24668'
      }
    ];
  },
  created() {
    this.setRouteListeners('priceCouponGoBack');
    this.$root.$eventHub.$on('priceCouponGoBack', data => {
      this.priceCouponGoBack();
      this.$root.$eventHub.$off('priceCouponGoBack'); //解绑当前监听，否则下轮监听还会持续叠加
    });
    window.fluttercallBack = this.fluttercallBack;
  },
  components: {
    cancelResonPop,
    approveRefused,
    noOrder,
    Map,
    completedOrder,
    receivedOrder,
    bottomContent,
    returnBox,
    projectGrade,
    browserLoader
  },
  beforeDestroy() {
    this.map && this.map.destroy();
    this.refreshHub && this.refreshHub.uninstall();
    clearTimeout(this.timeBtn);
    this.$root.$bus.$off('reloadBtn');
    let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1; //android终端
    const container = this.$cookies.get('container');
    if (isAndroid && !['browser', 'h5'].includes(container) && !this.SmartStorage.get('isH5')) {
      this.removeOnResumeEvent();
    }
  },
  methods: {
    editItem() {
      this.SmartStorage.set('editdataForm', this.onlineDetail);
      this.$router.push('/platformEditOrder');
    },
    refrashData(data) {
      data && this.$router.push('/platformIndex');
    },
    browserClose(){
      this.showDiDiH5 = false;
    },
    h5GoClose() {
      this.showDiDiH5 = false;
      !this.isDiDiqiye && this.$router.push('/platformIndex');
    },
    GetExtraBtns(flag) {
      let _this = this;
      let params = [this.onlineDetail.itemId];
      this.services.GetExtraBtns(params).then(function(res) {
        if (res.success) {
          let btn = {
            Txt: '满意度调查',
            Processor: 'goSurvayPage',
            status: res.content[0].status
          };
          if (res.content[0].status.status == 3) {
            _this.$iToast('订单结束14天后无法填写满意度调查');
            return;
          } else {
            _this.$refs.projectGrade.showPopup(_this.onlineDetail, btn);
          }
        }
      });
    },
    /**
     * 打开评分页面
     */
    goSurvayPage(item, btn) {
      this.GetExtraBtns();
    },
    useCarRemark(item, btn, index) {
      this.clickItem = item;
      this.isCarRemarkDialog = true;
    },
    showUseCarRemark(item, btn, index) {
      this.isCarRemarkDialog = true;
      this.showBtn = false;
    },
    async SaveUseCarRemark(actDescr) {
      this.isloding = false;
      let params = {
        ItemId: this.clickItem.itemId,
        UseCarRemark: actDescr
      };
      let _this = this;
      this.services.SaveUseCarRemark(params).then(function(res) {
        if (res.success) {
          _this.showBtn = true;
          _this.isCarRemarkDialog = false;
          _this.getOrderGetDetail();
        }
        _this.isloding = true;
      });
    },
    fluttercallBack(statu) {
      if (statu == 'resumed') {
        this.installSingler();
        this.MapLoaderM();
      }
    },
    setTimeTest() {
      this.iindex += 1;
      if (this.arr[this.iindex] && this.arr[this.iindex].lng) {
        this.arratGps.push(new AMap.LngLat(this.arr[this.iindex].lng, this.arr[this.iindex].lat));
      }
      this.dynamicLine();
      if (this.iindex == 5) {
        this.map && this.map.clearMap();
        this.getGetDetail();
      }
    },
    cancelOrders(data) {
      this.forceCancelOrder(data || this.params);
    },
    // 获取订单详情
    getOrderGetDetail() {
      this.lodingPrice = true;
      this.isFirst = true;
      let params = {
        proposalId: this.SmartStorage.get('proposalId') || this.params.proposalId,
        itemId: this.params.itemId || this.SmartStorage.get('orderDetail').ItemId
      };
      this.services.GetOnlineDetail(params).then(res => {
        if (res.success) {
          this.map && this.map.clearMap();
          this.onlineDetail = res.content;
          this.onlineDetail.itsItemData.passenger.name = this.StringExchangeEmoji(this.onlineDetail.itsItemData.passenger.name);
          this.onlineDetail.extOrderStatus == 100 &&
            this.onlineDetail.driverGps &&
            this.onlineDetail.driverGps.splice(
              this.onlineDetail.driverGps.findIndex(item => item.lng == 0),
              1
            );
          this.onlineDetail.itsButtons.filter(ele => {
            if (ele.buttonType == 1) this.hasOtherBtn = true;
          });
          // console.log('this.hasOtherBtn',this.hasOtherBtn);
          this.maplineArra = this.onlineDetail.status == -1 ? [] : this.onlineDetail.extOrderStatus > 40 ? this.onlineDetail.driverGps : this.onlineDetail.carBeforeArrivedGps;
          // &&
          //   this.onlineDetail.driverGps.length > 0
          // ? this.onlineDetail.driverGps
          // : this.onlineDetail.status != -1 && this.onlineDetail.status != 5
          // ? this.onlineDetail.carBeforeArrivedGps
          // : [];
          this.isFirst = false;
          // this.initmaps(this.che);
          this.MapLoaderM();
          this.Loading = false;
          this.lodingPrice = false;
          this.changStauts(res.content.status);
          let _this = this;
          this.timeBtn = setTimeout(function() {
            _this.$root.$bus.$emit('reloadBtn', _this.onlineDetail);
          }, 700);
        }
      });
    },
    goLevel(data, isDiDi, isScroll) {
      this.levelList.prices.filter(function(item) {
        return (item.typeSelected = false);
      });
      if (isDiDi) {
        this.isDiDi = true;
      } else {
        this.isDiDi = false;
        data.typeSelected = true;
      }
      this.$forceUpdate();
      !isScroll && document.getElementById(isDiDi ? '滴滴' : data.carLevel).scrollIntoView();
    },
    scrollEvent() {
      let priceCouponBox = document.getElementsByClassName('priceCouponBox')[0];
      let pricescrollBox = document.getElementsByClassName('priceCouponContent')[0];
      let approvePriceCouponBox = document.getElementsByClassName('approvePriceCouponBox')[0];
      if (this.isNoOrder && !this.isPlaceOrder) {
        let 滴滴 = document.getElementById('滴滴');
        let 经济型 = document.getElementById('经济型');
        let 舒适型 = document.getElementById('舒适型');
        if (pricescrollBox.scrollTop - 滴滴.offsetHeight > 0) {
          this.goLevel(this.levelList.prices[0], false, true);
        }
        if (pricescrollBox.scrollTop - 滴滴.offsetHeight - 经济型.offsetHeight > 0) {
          this.goLevel(this.levelList.prices[1], false, true);
        }
        if (pricescrollBox.scrollTop - 滴滴.offsetHeight - 经济型.offsetHeight - 舒适型.offsetHeight > 0) {
          this.goLevel(this.levelList.prices[2], false, true);
        }
      }

      if (this.$refs.pricescrollBox.scrollTop == 0) {
        this.goLevel(this.levelList.prices[0], true, true);

        if (approvePriceCouponBox) {
          approvePriceCouponBox.style.height = '5.3rem';
        } else {
          priceCouponBox.style.height = this.isReceivedOrder ? '3.8rem' : this.isCompletedOrder ? '2.7rem' : this.isPlaceOrder ? '4.2rem' : '5.2rem';
          pricescrollBox.style.height = this.isReceivedOrder ? '300px' : '400px';
        }
      } else {
        if (approvePriceCouponBox) {
          approvePriceCouponBox.style.height = '50%';
        } else {
          console.log(555555555)
          priceCouponBox.style.height = this.isReceivedOrder ? '4.2rem' : '55%';
          pricescrollBox.style.height = this.isReceivedOrder ? '300px' : '520px';
        }
      }
    },
    async chosePrices(data) {
      this.params.CarPrices = data.priceArr;
      this.params.amount = data.chosePricesInfo.minPrice;

      // this.params.ItemData.isPickupVip =
      //   data.chosePrices.pickupServiceFee > 0 ? true : false;
    },
    async saveItemP(type) {
      // if (this.params.ItemData.sourceCode == "ShenZhou") {
      //   await this.GetUserToken();
      //   if (!this.tokenFlage) {
      //     this.isloding = true;
      //     return false;
      //   }
      // }
      this.params.proposalId = this.SmartStorage.get('proposalId');
      this.params.IsCheckRepeatOrder = this.IsCheckRepeatOrder;
      this.params.ItemData.estimateDistance = this.estimateDistance;
      this.params.itemId = this.params.itemId ? this.params.itemId : this.params.ItemData.itemId;
      this.params.qty = this.params.ItemData.qty || '';
      // this.request.changeAutoExtractResponseError(false);
      this.services.SaveItemOrders(this.params).then(res => {
        if (res.success) {
          this.params.itemId = res.content;
          if (type == 1) {
            this.GetCarProtocol();
          } else {
            this.$iToast('保存成功');
            this.$router.push('/platformIndex');
          }
          res.content && ['msd'].includes(this.$cookies.get('tenant')) && this.SavePassenger(res.content);
        } else if (res.code == 1010) {
          this.isloding = true;
          this.epeatOrder(res, 1);
        }else if(res.code == 1003){
          this.$iToast(res.msg);
          return false;
        } else {
          this.isloding = true;
        }
      });
    },
    SavePassenger(itemId) {
      this.$store.state.passengerList.length > 0 &&
        this.$store.state.passengerList.forEach((element, index) => {
          if (element.passengers == this.params.ItemData.passenger.name && element.department == this.params.ItemData.passenger_department) {
            this.$store.state.passengerList.splice(index, 1);
          }
        });
      let passenger = {
        passengers: this.params.ItemData.passenger.name,
        department: this.params.ItemData.passenger_department
      };
      this.$store.state.passengerList.push(passenger);
      this.$store.state.passengerList.forEach(element => {
        element.passengers = this.EmojiExchangeString(element.passengers);
        element.organization = element.organization && this.EmojiExchangeString(element.organization);
        element.department = this.EmojiExchangeString(element.department);
      });
      let params = {
        BusinessId: itemId,
        Passengers: this.$store.state.passengerList,
        IsOnline: true
      };
      this.services.SavePassenger(params).then(res => {
        if (res.success) {
          this.$store.commit('SETpassengerList', false);
        }
      });
    },
    saveItem(type, isDiDi) {
      let flage = 1;
      this.saveType = type;
      this.levelList.userPersonalizations.personalizations.forEach(element => {
        if (element.code == 'ShenZhouUplus' && element.tips == true && this.params.ItemData.sourceCode == 'ShenZhou') {
          flage = 2;
        }
      });
      if (isDiDi) {
        this.params.ItemData.isUseH5 = true;
        this.params.CarPrices = this.didiPriceList.didiList;
      } else {
        this.params.ItemData.isUseH5 = false;
      }
      if (this.params.CarPrices.length < 1) {
        this.$iToast('请至少选择一种车型！');
        this.isloding = true;
        return false;
      }
      if (flage == 2) {
        this.$refs.setDetail.openPop(this.levelList.userPersonalizations);
      } else {
        this.isloding = false;
        this.$iDelay(() => {
          // console.log(this.params);
          this.saveItemP(type);
        }, 400);
      }
    },
    priceCouponGoBack() {
      // this.removeRouteListeners("priceCouponGoBack");
      // this.$store.commit('setValue', {
      //   key: 'homeWait',
      //   value: {
      //     date: new Date(),
      //     type: 'back'
      //   }
      // });
      this.arrryLeves = [];
      this.levelList = {
        userPersonalizations: {},
        prices: []
      };
      this.levelList = {};
      this.params.SourceCode = 0;
      this.params.amount = '';
      // console.log(this.isPlaceOrder,this.isReceivedOrder,this.isCompletedOrder)
      this.$route.query.isFromeAll
        ? this.$router.push('/allCarOrder')
        : this.SmartStorage.get('orderDetail') || this.isPlaceOrder || this.isReceivedOrder || this.isCompletedOrder
        ? this.$router.push('/platformIndex')
        : this.$router.push('/platformEditOrder');
      this.SmartStorage.remove('orderDetail');
      this.SmartStorage.remove('priceCouponData');
    },
    comparePrice(key) {
      return function(obj1, obj2) {
        if (Number(obj1[key]) < Number(obj2[key])) {
          return -1;
        } else if (obj1[key] === obj2[key]) {
          return 0;
        } else {
          return 1;
        }
      };
    },
    getPriceCouponStream(data) {
      this.lodingPrice = true;
      this.levelList = {
        userPersonalizations: {},
        prices: []
      };
      data.IsUseGaoDeApi = true;
      data.IsMergeCar = true;
      let _this = this;
      let token = this.$cookies.get("token");
      let apiVersion = this.SmartStorage.get("apiVersion");
      let env = process.env.NODE_ENV;
      this.Loading = true;
      let Url =process.env.VUE_APP_NCAR;
      // let Url = ["roche"].includes(this.$cookies.get("tenant"))
      //   ? env == "production"
      //     ? "https://a1-ncar.roche.com.cn"
      //     : "https://a1-ncar-test.roche.com.cn"
      //   // : 
      //   // env == "production" && apiVersion == "20200514"
      //   // ? "https://ncar-gray.smartmice.cn"
      //   : process.env.VUE_APP_NCAR;
      axios.defaults.headers.post["Content-Type"] =
        "application/json; charset=utf-8";
      return axios({
        url: Url + "/api/OnlineCar/GetPriceCouponStream",
        // url: "https://ncar.smartmice.cn/api/OnlineCar/GetPriceCouponStream",
        // url: "https://ncar-dev.eventworld.cn/api/OnlineCar/GetPriceCouponStream",//本地测试
        // url: "https://ncar-mice.smartmice.cn/api/OnlineCar/GetPriceCouponStream",
        // url: "https://ncar-gray.smartmice.cn/api/OnlineCar/GetPriceCouponStream",
        method: "post",
        data: data,
        headers: {
          Authorization: "Bearer " + token,
          Version: apiVersion,
        },
        onDownloadProgress: (progressEvent) => {
          this.obj = { 经济型: [], 舒适型: [], 商务型: [] };
          let arr = "[" + progressEvent.currentTarget.response + "]";
          let leves = eval("(" + arr + ")");
          // console.log(progressEvent.currentTarget.response);
          // console.log(leves);
          // if(leves.content.prices[0].prices.sourceCode=='DiDi'){
          // }
          _this.arrryLeves = leves;
          _this.levelList.userPersonalizations =
            _this.arrryLeves.length > 0 &&
            _this.arrryLeves[0].content.userPersonalizations;
          _this.arrryLeves.length > 0 &&
            _this.arrryLeves.forEach((element, index) => {
              // element.content.prices[0].sourceCode=="DiDi"
              element.content.prices.forEach((ele) => {
                if (ele.prices[0].sourceCode == "DiDi") {
                  _this.didiPrice = element.content.prices;
                } else {
                  _this.obj[ele.carLevel].unshift(...ele.prices);
                }
                // ([ 'dsm'].includes(this.$cookies.get('tenant'))&&ele.prices[0].sourceCode=="DiDi")?_this.obj[ele.carLevel].push(...ele.prices):
                // console.log(ele)
                // _this.obj[ele.carLevel].push(...ele.prices);
              });
            });
          let didiList = [];
          _this.didiPrice.forEach((element) => {
            didiList = [...didiList, ...element.prices];
          });
          _this.didiPriceList = {
            didiList: didiList.sort(this.comparePrice("price")),
            minPrice:
              didiList.length > 0
                ? Math.min.apply(
                    Math,
                    didiList.map((item) => item.price)
                  )
                : 0,
            maxPrice:
              didiList.length > 0
                ? Math.max.apply(
                    Math,
                    didiList.map((item) => item.price)
                  )
                : 0,
          };
          _this.levelList.prices = _this.arrryLeves.length > 0 && [
            {
              carLevel: "经济型",
              minPrice:
                _this.obj.经济型.length > 0
                  ? Math.min.apply(
                      Math,
                      _this.obj.经济型.map((item) => item.price)
                    )
                  : 0,
              maxPrice:
                _this.obj.经济型.length > 0
                  ? Math.max.apply(
                      Math,
                      _this.obj.经济型.map((item) => item.price)
                    )
                  : 0,
              prices: _this.obj.经济型.sort(this.comparePrice("price")),
              selected: false,
            },
            {
              carLevel: "舒适型",
              minPrice:
                _this.obj.舒适型.length > 0
                  ? Math.min.apply(
                      Math,
                      _this.obj.舒适型.map((item) => item.price)
                    )
                  : 0,
              maxPrice:
                _this.obj.舒适型.length > 0
                  ? Math.max.apply(
                      Math,
                      _this.obj.舒适型.map((item) => item.price)
                    )
                  : 0,
              prices: _this.obj.舒适型.sort(this.comparePrice("price")),
              selected: false,
            },
            {
              carLevel: "商务型",
              minPrice:
                _this.obj.商务型.length > 0
                  ? Math.min.apply(
                      Math,
                      _this.obj.商务型.map((item) => item.price)
                    )
                  : 0,
              maxPrice:
                _this.obj.商务型.length > 0
                  ? Math.max.apply(
                      Math,
                      _this.obj.商务型.map((item) => item.price)
                    )
                  : 0,
              prices: _this.obj.商务型.sort(this.comparePrice("price")),
              selected: false,
            },
          ];
          // _this.timeerp = setTimeout(function () {
          //   _this.$root.$bus.$emit(
          //     "reloadPriceData",
          //     _this.obj.经济型.length > 0 ? _this.obj.经济型 : _this.obj.舒适型
          //   );
          // }, 700);
          this.$forceUpdate();
          this.$refs.noOrderRef && this.$refs.noOrderRef.chosePricesO();
          _this.Loading = false;
        },
      }).then(function () {
        _this.lodingPrice = false;
      });
    },
    MapLoaderM() {
      let that = this;
      that.map = new AMap.Map('containerP', {
        showTraffic: false, //实时路况信息
        resizeEnable: true,
        expandZoomRange: true
      });
      if (that.onlineDetail.status != -1) {
        that.map.setFeatures(['road', 'point']);
        that.infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30)
        });
        that.lineDriving = new AMap.Driving({
          map: that.map,
          hideMarkers: true,
          outlineColor: 'balck',
          autoFitView: false
        });
        let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1; //android终端
        const container = this.$cookies.get('container');
        if (isAndroid && !['browser', 'h5'].includes(container) && !that.SmartStorage.get('isH5')) {
          that.addOnResumeEvent();
        }
        if (that.onlineDetail.sourceCode == 'EHI') {
          that.gpsconvertFrom(that.maplineArra);
        } else {
          that.initmaps(that.maplineArra);
          that.maplineArra.forEach(element => {
            that.arratGps.push(new AMap.LngLat(element.lng, element.lat));
          });
          // this.initmaps(that.che);
          // setInterval(this.setTimeTest, 5000);
        }
      } else {
        this.addyMarck2();
        this.map.panBy(20, -100);
      }
    },
    gpsArraty(data) {
      let that = this;
      switch (data.serviceStatus) {
        case 'acceptService':
        case 'cancelService':
          that.getOrderGetDetail();
          break;
        case 'serviceStarted':
        case 'arriveService':
          that.arratGps = [];
          that.maplineArra = [];
          that.map.remove(that.polyline);
          that.map.remove(that.marker);
          that.getOrderGetDetail();
          break;
        case 'serviceFinished':
        case 'finishService':
          that.map && that.map.clearMap();
          that.getOrderGetDetail();
          break;
        default:
          break;
      }
      if (data && data.driverGps && data.driverGps.lng) {
        that.arratGps.push(new AMap.LngLat(data.driverGps.lng, data.driverGps.lat));
        that.dynamicLine();
      }
    },
    changStauts(status) {
      switch (status) {
        //呼叫中
        case 2:
          this.isNoOrder = true;
          this.isPlaceOrder = true;
          this.isReceivedOrder = false;
          this.isCompletedOrder = false;
          this.$forceUpdate();
          break;
        //已完成
        //已取消
        // 未下单
        case 5:
        case -1:
        case 0:
          this.isNoOrder = false;
          this.isReceivedOrder = false;
          this.isCompletedOrder = true;
          this.isPlaceOrder = false;
          break;
        default:
          this.isNoOrder = false;
          this.isReceivedOrder = true;
          this.isCompletedOrder = false;
          this.isPlaceOrder = false;
          break;
      }
    },
    showApprovePop(item) {
      console.log('item==',item);
      if (item.actCode == 'approved') {
        this.approveSuccess(item);
      } else {
        this.$refs.approveRefused.showPopup(item);
      }
    },
    installSingler() {
      let that = this;
      let itemId = this.params.itemId || this.SmartStorage.get('orderDetail').ItemId;
      that.refreshHub = new this.iSignalr({
        hubName: 'SmartHub',
        queryParams: 'group=' + itemId,
        listeners: [
          {
            methodName: 'Refresh',
            method: function(datas) {
              if (that.maplineArra.length < 1 && ![-1, 0, 2, 5].includes(that.onlineDetail.status) && datas.driverGps) {
                that.maplineArra.push(datas.driverGps);
                that.initmaps(that.maplineArra);
              }
              if (that.onlineDetail.sourceCode == 'EHI') {
                AMap.convertFrom(AMap.LngLat(datas.driverGps.lng, datas.driverGps.lat), 'baidu', function(status, result) {
                  if (result.info === 'ok') {
                    datas.driverGps = result.locations[0];
                    that.gpsArraty(datas);
                  }
                });
              } else {
                that.gpsArraty(datas);
              }
            }
          }
        ],
        // host: "http://signalr.smartmice.cn",
        host: process.env.VUE_APP_SIGNALER
      });
      that.refreshHub.install();
    },
    getQueryTaskActions() {
      let params = {
        processId: this.SmartStorage.get('orderDetail')?.ItemId || this.onlineDetail.ItemId,
        taskId: this.SmartStorage.get('orderDetail')?.TaskId || this.onlineDetail.taskId,
        userId: this.SmartStorage.get('userId')
      };
      this.services.QueryTaskActions(params).then(res => {
        if (res.success) {
          this.approveBtn = res.content;
        }
      });
    },
    approveSuccess(item) {
      this.$iMessageBox.render({
        messageTitle: '提示',
        messageTxt:'确认审批通过？',
        onConfirm: function() {
          this.PerformTask(item);
        }.bind(this)
      });
    },
    PerformTask(item){
      let params = {
        taskId: this.SmartStorage.get('orderDetail')?.TaskId,
        actionId: item.actionId
      };
      this.services.PerformTask(params).then(res => {
        if (res.success) {
          this.$toast(`操作成功`);
          this.$router.push('/platformIndex');
          // this.getOrderGetDetail();
          // this.getQueryTaskActions();
        }
      });
    }
  }
};
</script>
<style lang="scss">
@import '@/assets/platform/carLeve.scss';
.messageBoxSvg {
  display: none;
}
.messageTxt {
  padding: 0.3rem;
}
.didiMask {
  position: fixed;
  width: 100%;
  top: 0.39rem;
  height: 69%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9;
  text-align: center;
  line-height: 6rem;
  color: #fff;
}
.car_remarks {
  display: flex;
  flex-direction: column;
  padding:.1rem 0.15rem;
  border-radius: 0.04rem;
  // background: #fff;
  margin-top: 0.05rem;
  bottom: 0;
  // position: absolute;
  &_title {
    font-size: 0.16rem;
    text-align: left;
    font-weight: bold;
    display: flex;
    align-items: center;
    .statuIcon {
      margin-left: 0.05rem;
      color: red;
    }
  }
  &_con {
    text-align: left;
    word-break: break-all;
    font-size: 0.13rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.approve_btn {
  display: flex;

  flex-direction: column;

  position: absolute;
  width: 95%;
  background: #fff;
  margin: 0 0.1rem;
  border-radius: 0.04rem;
  &_list {
    display: flex;
    div {
      margin: 0 1%;
      width: 45%;
      height: 0.32rem;
      border-radius: 0.04rem;
      border: none;
      padding: 0.04rem 0.08rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    div:nth-child(2) {
      color: #fff;
      background: var(--themeColor);
    }
    div:nth-child(1) {
      background: #fff;
      color: #999b9c;
      border: 1px solid #f5f5f5;
    }
  }
}
</style>