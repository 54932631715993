<template>
  <div class="receivedOrderBox">
    <div class="leveInfor">
      <img :src="onlineDetail.extOrderDataDynamicData.sourceIconUrl" @error="headError"/>
      {{onlineDetail.extOrderDataDynamicData.sourceName}} 最快接单，将为您提供本次服务
    </div>
    <div class="detailInfo">
      <h4>{{onlineDetail.extOrderStatusTxt}}</h4>
      <p class="tips" v-if="onlineDetail.extOrderStatus==10">预约订单已下单成功，车辆司机信息将在出发30分钟前通知您，请耐心等待</p>
      <p class="tips" v-if="onlineDetail.extOrderStatus<40&&onlineDetail.extOrderStatus>10">司机已赶来，请提前前往上车地点，做好乘车准备</p>
      <p class="tips" v-if="onlineDetail.extOrderStatus>40">行程开始了，请后排落座，并系好安全带</p>
      <div class="colorBox">此订单将由企业支付，下车后请及时确认车费</div>
      <!-- <p class="orderInforBefore" v-if="onlineDetail.extOrderStatus==10">预约订单已下单成功，车辆司机信息将在出发30分钟前通知您，请耐心等待</p> -->
      <div class="driverDetailInfo" v-if="onlineDetail.extOrderDataDynamicData.driverCode">
        <div class="driverInfoLeft">
          <p class="carNumber">{{onlineDetail.extOrderDataDynamicData.driverCode}}</p>
          <div class="cardetail">
            <span v-if="onlineDetail.extOrderDataDynamicData.driverColor">{{onlineDetail.extOrderDataDynamicData.driverColor}}.</span>
            <span>{{onlineDetail.extOrderDataDynamicData.driverCarType}}·{{onlineDetail.extOrderDataDynamicData.driverName}}</span>
          </div>
        </div>
        <img src="../../../../assets/images/carDriver.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["onlineDetail"],
  methods:{
    headError(e){
      e.target.src = require("../../../../assets/images/defaultSup.png");
 },
  }
};
</script>