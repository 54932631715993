<template>
  <div class="orderDetail">
    <div v-if="isGoBrowser" class="WXShadow">
      <div class="shadowtext">
        <div class="wxtitle">温馨提示</div>

        <template>
          <!-- <p>小程序无法打开当前界面</p> -->
          <p>请点击下方复制链接按钮或长按链接复制，然后在手机浏览器中粘贴访问，继续完成操作</p>
          <input id="urlBox" class="urlBox" :value="shortUrlProto" type="text" />
          <!-- {{shortUrlProto}}
                    </input> -->
          <!-- <input type="text" :value="shortUrlProto" style="border: 1px solid gray; background: #c4c4c4; border-radius: 4px; font-size: 14px; width: 220px; padding: 5px 0; margin-top: 10px" /> -->
        </template>
        
        <a class="closeBtn" @click="h5GoClose">我知道了</a>
        <a @click="copyUrl">复制链接</a>
      </div>
    </div>
    <div class="priceDrtail" v-else>
      <div class="backBox" @click="h5GoBack">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#iconleft" />
        </svg>
      </div>
      <iframe :src="iframeUrl" id="h5Frame" class="h5Frame" ref="h5Frame"></iframe>
    </div>
  </div>
</template>
<script>
export default {
  props: ['iframeUrl', 'h5GoClose', 'browserClose'],
  data() {
    return {
      shortUrlProto: '',
      isGoBrowser: this.$cookies.get('tenant') == 'roche' && (['browser', 'h5'].includes(this.$cookies.get('container')) || this.SmartStorage.get('isH5')) ? true : false
    };
  },
  components: {},
  computed: {},
  async mounted() {
    let _this = this;
    if (this.isGoBrowser) {
      let params = {
        url: this.iframeUrl,
        generatePolicy: 0,
        context: {
          data: {},
          cacheEntryOptions: { absoluteExpirationRelativeToNow: '0:20:0:0' }
        }
      };
      this.services.common(process.env.VUE_APP_TURL+'/api/Generic/Shorten', params).then(function (res) {
        if (res && res.success) {
          _this.shortUrlProto = res.content.substring(8);
        }
      });
    } else {
      let h5iframe = document.getElementById('h5Frame');
      var state = {
        title: 'title',
        url: '#'
      };

      window.history.pushState(state, 'title', '#');
      window.addEventListener(
        'popstate',
        function (e) {
          _this.h5GoClose();
          // alert("已监听到你从上个页面返回！");
        },
        false
      );
    }
    console.log(document.querySelector('.urlBox'));

    document.querySelector('.urlBox').addEventListener('click', e => {
      // 获取到 web-view 元素节点
      _this.copyUrl();
    });
  },
  methods: {
    copyUrl() {
      let _this = this;
      const webView = document.querySelector('web-view');
      const tempInput = document.createElement('input');
      tempInput.value = _this.shortUrlProto;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      _this.$iToast('复制成功');
    },
    h5GoBack() {
      window.history.go(-1);
      return false;
      // window.history.back();
    },
    loadFrame(obj) {
      console.log(obj);
      // let h5iframe=document.getElementById('h5Frame');
      // var url = h5iframe.contentWindow.location.href;
      // console.log(h5iframe);
      // if (url.indexOf("bizOrderId") != -1) {
      //   console.log(url);
      // }
    }
  }
};
</script>
<style scoped="scoped" lang="scss">
.priceDrtail {
  position: fixed;
  // top: 0.44rem;
  top: 0;
  height: 100%;
  background: #fff;
  font-size: 0.15rem;
  width: 100%;
  z-index: 100;
  padding: 0;
  overflow: auto;
  .h5Frame {
    width: 100vw;
    height: 100vh;
  }
}
.backBox {
  position: fixed;
  width: 0.14rem;
  height: 0.14rem;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  z-index: 101;
  padding: 0.1rem;
  float: left;
  .icon {
    width: 1em;
    height: 1em;
    color: #313131;
    vertical-align: -0.015em;
    fill: currentColor;
    overflow: hidden;
    // margin-top: 0.1rem;
    background: #fff;
    border-radius: 50%;
    /* padding: .1rem; */
    /* font-size: 0.2rem; */
    // border: 0.1rem solid #fff;
  }
}
.closeBox {
  // top: 0.54rem;
  top: 0;
  left: 90%;
  /* left: initial; */
  right: 0.1rem;
}
.WXShadow {
  .wxtitle{
    font-weight: bolder;
    font-size: .16rem;
  }
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0px;
  z-index: 2100;
  img {
    width: 36%;
    height: 25%;
    float: right;
    margin-right: 0.2rem;
    margin-top: 0.2rem;
  }
  .shadowtext {
    font-size: 0.2rem;
    position: fixed;
    top: 2rem;
    width: 80%;
    background: #fff;
    left: 5%;
    text-align: center;
    padding: 0.15rem;
    padding-bottom: 0.25rem;
    border-radius: 0.1rem;
    box-shadow: 0 0 9px 0px #2a292966;
    p {
      text-align: left;
      margin-top: 0.12rem;
      color: #5b5a5a;
    }
    .urlBox {
      pointer-events: all;
      border: 1px solid #ededed;
      // background: #c4c4c4;
      border-radius: 4px;
      // font-size: 14px;
      font-size: 0.16rem;
      font-weight: bold;
      width: 96%;
      padding: 6px;
      margin-top: 10px;
      margin: 10px auto;
      text-align: left;
    }
    span {
      font-size: 0.16rem;
    }
    a {
      display: block;
      width: 44%;
      /* font-size: 0.16rem; */
      background: var(--themeColor);
      color: #fff;
      padding: 0.1rem 0;
      border-radius: 50px;
      /* margin: 0 auto; */
      margin-top: 0.22rem;
      float: left;
      margin-left: 9%;
    }
    .closeBtn {
      margin-left: 0%;
      background: #fff;
      color: #999;
      border: 1px solid #999;
    }
  }
}
.goBrowserTips {
  img {
    width: 80%;
    height: auto;
    margin-left: 10%;
  }
  .shadowtext {
    padding: 0.2rem;
    margin-top: 1rem;
    div {
      font-weight: 600;
      margin-bottom: 0.2rem;
    }
    span {
      display: block;
      font-size: 0.16rem;
      margin: 0.2rem 0;
    }
    input {
      border: 1px solid gray;
      background: #c4c4c4;
      border-radius: 4px;
      font-size: 14px;
      width: 80%;
      padding: 5px 0;
      margin-top: 10px;
    }
    a {
      display: block;
      width: 60%;
      font-size: 0.16rem;
      background: var(--themeColor);
      color: #fff;
      padding: 0.12rem 0.48rem;
      border-radius: 50px;
      margin: 0 auto;
      margin-top: 0.5rem;
    }
  }
}
</style>
