<!--
 * @Descripttion: 山德士审批拒绝页面
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-09-19 14:32:55
-->
<template>
  <mt-popup v-model="isShowPopup" position="bottom" class="popup_box">
    <div class="approve_refused">
      <Header backType="blank" :backEvent="true" @back-event="approveRefusedGoBack()" :back="true" :headStyle="{ background: 'var(--themeColor)', color: '#fff' }">
        <div slot="headText">
          <span class="head-txt">
            退回原因
          </span>
        </div>
      </Header>
      <div class="approve_refused_con">
        <template>
          <p class="approve_refused_title">请填写退回订单的原因</p>
          <textarea placeholder="请填写拒绝确认订单的原因" class="choseInput" v-model="rebackDescr" maxlength="100"></textarea>
        </template>
      </div>
      <div class="approve_refused_btn">
        <div @click="approveRefusedGoBack()">返回</div>
        <div @click="approveConfirm()">确定</div>
      </div>
    </div>
  </mt-popup>
</template>

<script>
export default {
  name: 'approveRefused',
  data() {
    return {
      isShowPopup: false, // 组件的开启和关闭
      rebackDescr: '',
      btnContent: '',
      placeholder: '',
      changeDescr: '' //更改补充用车事由
    };
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup) {
        this.setRouteListeners('approveRefusedGoBack'); //保存当前路由返回事件记录
        this.$root.$eventHub.$on('approveRefusedGoBack', data => {
          this.approveRefusedGoBack();
          this.$root.$eventHub.$off('approveRefusedGoBack');
        });
      } else {
        this.$emit('close'); //销毁组件
        this.removeRouteListeners('approveRefusedGoBack'); //返回删除记录
      }
    }
  },
  methods: {
    approveRefusedGoBack() {
      this.isShowPopup = false;
    },
    async showPopup(btn) {
      console.log('000000000', btn);
      this.isShowPopup = true;
      this.btnContent = btn;
    },
    approveConfirm() {
      if (!this.rebackDescr) {
        this.$toast(`请填写必填信息`);
        return;
      }
      let params = {
        taskId: this.SmartStorage.get('orderDetail')?.TaskId,
        actionId: this.btnContent.actionId,
        TaskData: {
        Descr:this.rebackDescr,
    }
      };
      this.services.PerformTask(params).then(res => {
        if (res.success) {
          this.$toast(`操作成功`);
          this.isShowPopup = false;
          this.$parent.getQueryTaskActions();
          this.$parent.getOrderGetDetail();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.approve_refused_con {
  background: #fff;
  padding: 0.15rem;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.04rem;
  display: flex;
  flex-direction: column;
  height: 90vh;

  p {
    text-align: left;
  }
}
.approve_refused_title {
  font-size: 0.15rem;
  font-weight: bold;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #13161b;
  p {
    word-break: break-all;
  }
}
.approve_refused_tips {
  color: $txt_color;
  font-size: 0.13rem;
  margin-bottom: 0.1rem;
}
.approve_refused_btn {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 1rem;
  width: 90%;
  margin: 0 2.5%;

  div {
    border-radius: 0.04rem;
    width: 35%;
    margin: 1%;
    padding: 0.1rem;
    &:last-child {
      background: var(--themeColor);
      color: #fff;
    }
    &:first-child {
      border: 1px solid #f5f5f5;
      color: #909398;
    }
  }
}
textarea {
  border: 1px solid #f5f5f5;
  width: 100%;
  text-align: left;
  height: 1rem;
  padding: 0.05rem;
  box-sizing: border-box;
  border-radius: 0.04rem;
  &::placeholder {
    color: $sub_txt_color;
    font-size: 0.13rem;
  }
}
</style>
