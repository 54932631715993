<template>
  <div>
    <div class="didiBox" id="滴滴" v-if="!isPlaceOrder">
      <div class="didiContent">
        <p>
          <svg class="icon didiIcon" aria-hidden="true">
            <use xlink:href="#icondidichuhang" />
          </svg>
          滴滴
        </p>
        <span class="opBackSapn" @click="goDiDiH5()">进入滴滴</span>
      </div>
      <div class="levelbox">
        <template v-if="didiPriceList.didiList.length > 0">
          <span v-for="(dtype, dindex) in didiPriceList.didiList" :key="dindex">
            {{ dtype.require_level_txt }}
            <p>
              预估
              <span>{{ dtype.price || 0 }}</span>
              元
            </p>
          </span>
        </template>
        <template v-else>
          <span>暂无报价</span>
        </template>
      </div>
    </div>
    <div class="driverInfo" v-else>
      <div class="topBox">
        <p>
          正在持续为你呼叫
          <svg class="icon loadingIcon" aria-hidden="true">
            <use xlink:href="#iconloading" />
          </svg>
        </p>
        <a @click="cancelOrders()">取消订单</a>
      </div>
      <p class="grayFont" v-if="chosePricesSection && chosePricesSection.nuPriceArr && chosePricesSection.nuPriceArr[0]">
        含{{ chosePricesSection.nuPriceArr[0].brand_name }}等{{ chosePricesSection.nuPriceArr.length || 0 }}种车型，预估{{ chosePricesInfoN.minPrice }}-{{ chosePricesInfoN.maxPrice }}元
      </p>
      <p class="qiyeInfo">此订单将由企业支付，下车后请及时确认车费</p>
      <div class="timeBox">
        <svg class="icon timeIcon waitIcon" aria-hidden="true">
          <use xlink:href="#iconloudou" />
        </svg>
        <p>
          正在努力为你预约
          <span>{{ itemData && (itemData.carRule == 14 ? '现在' : itemData.departure_time + '出发') }}</span>
          出发的车辆，感谢你的耐心等待
        </p>
      </div>
    </div>
    <div class="juheBox">
      <p class="juheTitle">
        {{ isPlaceOrder ? '添加更多车型' : '聚合叫车，可同时呼叫多辆车' }}
      </p>
      <ul>
        <div class="carItems" :id="lItem.carLevel" v-for="(lItem, lindex) in levelList" :key="lindex">
          <template v-if="lItem.prices.length > 0">
            <div class="levelCount">
              <p>
                {{ lItem.carLevel }}
                <i>{{ lItem.prices.length }}</i>
              </p>
              <div @click="choiseAll(lItem)">
                全选{{ lItem.carLevel }}
                <svg :class="['icon', 'choiseAllIcon', lItem.selected && 'choiseAllIconSelected']" aria-hidden="true">
                  <use :xlink:href="lItem.selected ? '#iconcheckboxok' : '#iconcheckboxno'" />
                </svg>
              </div>
            </div>
            <li v-for="(jitem, jindex) in lItem.prices" :key="jindex" @click.stop="choiseSpu(jitem)">
              <img :src="jitem.sourceIconUrl" @error="headError" />
              <!-- <img :src="jitem.sourceIconUrl"  :onload="loadImg(item.url)"/> -->
              <div>
                <p>{{ jitem.brand_name || jitem.sourceTxt }}</p>
                <span>隐私保护，敢做敢赔</span>
              </div>
              <p class="priceCon">
                预估
                <span>{{ jitem.price }}</span>
                元
                <svg :class="['icon', 'checkIcon', !jitem.isCanSelect && 'noCheckIcon', (!jitem.isCanSelect || jitem.isSelected) && 'onCheckIcon']" aria-hidden="true">
                  <use :xlink:href="!jitem.isCanSelect || jitem.isSelected ? '#iconcheckboxok' : '#iconcheckboxno'" />
                </svg>
              </p>
            </li>
          </template>
        </div>
        <div class="nodataDiv" v-if="levelList && isNoPrice">
          <p class="noinfor">暂无符合的运力报价，请稍后再试</p>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['levelList', 'didiPriceList', 'isPlaceOrder', 'itemData', 'saveItem', 'params', 'cancelOrders'],
  data() {
    return {
      chosePricesInfoN: {},
      chosePricesSection: {},
      timeerp: {},
      isNoPrice: true
    };
  },
  mounted() {
    this.chosePricesO();
  },
  beforeDestroy() {
    clearTimeout(this.timeerp);
  },
  methods: {
    headError(e) {
      e.target.src = require('../../../../assets/images/defaultSup.png');
    },
    goDiDiH5() {
      this.chosePricesO(1);
      this.saveItem(1, true);
    },
    choiseSpu(item) {
      if (item.isCanSelect) {
        item.isSelected = !item.isSelected;
        this.chosePricesO();
        this.$forceUpdate();
      }
    },
    choiseAll(data) {
      data.selected = !data.selected;
      data.prices.filter(function(item) {
        if (item.isCanSelect) {
          return (item.isSelected = data.selected);
        }
      });
      this.chosePricesO();
      this.$forceUpdate();
    },
    chosePricesO(type) {
      let arr = [];
      let unarr = [];
      let num = 0;
      let prices = [];
      let nuPrice = [];
      let typeList = [];
      console.log('chosePricesO==', this.levelList);
      this.levelList.forEach(element => {
        if (type == 1) element.selected = false;
        prices = element.prices.filter(value => {
          if (type == 1) value.isSelected = false;
          return value.isCanSelect && value.isSelected;
        });
        nuPrice = element.prices.filter(value => {
          if (type == 1) value.isSelected = false;
          return value.isSelected;
        });
        if (element.prices.length > 0) {
          this.isNoPrice = false;
        }
        prices.length > 0 && num++;
        prices.length > 0 && typeList.push(element.carLevel);
        arr = [...arr, ...prices];
        unarr = [...unarr, ...nuPrice];
      });
      this.chosePricesInfoN.minPrice =
        unarr.length > 0
          ? Math.min.apply(
              Math,
              unarr.map(item => item.price)
            )
          : 0;
      this.chosePricesInfoN.maxPrice =
        unarr.length > 0
          ? Math.max.apply(
              Math,
              unarr.map(item => item.price)
            )
          : 0;
      this.chosePricesInfoN.typeNum = num;
      this.chosePricesInfoN.typeList = typeList;
      let chosePrices = {
        priceArr: arr,
        nuPriceArr: unarr,
        chosePricesInfo: this.chosePricesInfoN
      };

      this.$emit('chosePrices', chosePrices);
      let _this = this;
      console.log('chosePrices===', chosePrices);
      _this.chosePricesSection = chosePrices;
      _this.timeerp = setTimeout(function() {
        _this.$root.$bus.$emit('reloadPriceData', chosePrices);
      }, 700);
    }
  }
};
</script>

<style></style>
